import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

const Partners = () => {
  const { t } = useTranslation()

  const partners_list = t('page_home_section_partners_list', {
    returnObjects: true,
  })

  return (
    <section className="partners container ">
      <h2 className="partners__title">
        {t('page_home_section_partners_title')}
      </h2>
      <div className="partners__container">
        {partners_list.map((partner, i) => (
          <div key={i} className="partners__icon">
            <img src={partner.logo} alt={partner.name} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default Partners
